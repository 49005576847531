
export default {
  name: "ActionButton",
  props: {
    btnDisabled: {
      type: Boolean,
      default: () => false,
    },
    btnActive: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    testId: {
      type: String,
      default: () => "",
    },
    icon: {
      type: String,
      default: () => "",
    },
    tooltipText: {
      type: String,
      default: () => "",
    },
    tooltipDirection: {
      type: String,
      default: () => "left",
    },
    classes: {
      type: [String, Object],
      default: () => "",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: [Number, undefined],
      default: undefined,
    },
    elevation: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    buttonClasses() {
      const propClasses =
        typeof this.classes === "string"
          ? { [this.classes]: true }
          : this.classes

      return {
        "action-btn--expanded": this.expanded,
        ...(propClasses || {}),
      }
    },
    buttonStyles() {
      let styles = {}
      if (this.padding) {
        styles = {
          padding: `${this.padding}px`,
        }
      }

      return styles
    },
  },
}
